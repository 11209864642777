import {Container, Content, Box} from './style.js';
import Legalization from "../../assets/legalization.png";
import Tax from "../../assets/tax.png";
import Planning from "../../assets/planning.png";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
function Services() {
  return (
   <Container id='services'>
    <h2>Serviços de contabilidade personalizados</h2>
    <p>Oferecemos soluções completas para a sua empresa! Atuamos nas áreas de contabilidade, 
    setor fiscal e pessoal, cuidando de todas as necessidades contábeis com precisão e eficiência.</p>
    <Content>
      <Box>
        <img src={Legalization}></img>
        <h3>Legalização de empresas</h3>
        <div className='boxer'>
          <div className='blue'><ArrowOutwardIcon /></div>
          <hr></hr>
        </div>
      </Box>
      <Box>
        <img src={Tax}></img>
        <h3>Imposto de Renda</h3>
        <div className='boxer'>
          <div className='blue'><ArrowOutwardIcon /></div>
          <hr></hr>
        </div>
      </Box>
      <Box>
        <img src={Planning}></img>
        <h3>Planejamento tributário</h3>
        <div className='boxer'>
          <div className='blue'><ArrowOutwardIcon /></div>
          <hr></hr>
        </div>
      </Box>
    </Content>
   </Container>
  );
}

export default Services;
