import React from "react";
import { CarouselContainer, Slider as StyledSlider } from './style.js'; // Renomeia o Slider estilizado
import ReactSlick from "react-slick"; // Renomeia o Slider do react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Tecla from "../../assets/tecla.png";
import Zaevo from "../../assets/zaevo.png";
import Conjo from "../../assets/conjo.png";
import Erika from "../../assets/erika.png";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      <ReactSlick {...settings}>
        <div>
          <img src={Tecla} alt="Tecla T" />
        </div>
        <div>
          <img src={Zaevo} alt="Zaevo" />
        </div>
        <div>
          <img src={Conjo} alt="Conjo" />
        </div>
        <div>
          <img src={Erika} alt="Erika" />
        </div>
      </ReactSlick>
    </CarouselContainer>
  );
}
