import styled, { keyframes } from 'styled-components';

// Definição da animação
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Move os itens para a esquerda */
  }
`;

export const CarouselWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100px;
  background-color: #0d0d2b;
  display: flex;
  align-items: center;
`;

export const CarouselContent = styled.div`
  display: flex;
  gap: 40px;
  animation: ${scroll} 10s linear infinite; /* Aplica a animação aqui */
`;

export const CarouselItem = styled.div`
  flex-shrink: 0;
  min-width: 25%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 20px;

  img {
    width: 40px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
  }
`;
