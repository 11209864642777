import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcJmI8qAAAAAPRbJZxZmqXKS0c26SW29f_BMXWh"
      language="en" // Replace with the desired language code
      useRecaptchaNet={false} // Replace with the desired boolean value
      useEnterprise={false} // Replace with the desired boolean value
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined
      }}
      // Omit container if not needed or configure it properly
      // container={{
      //   element: "recaptcha-container", // Replace with the actual element ID or HTML element
      //   parameters: {
      //     badge: 'bottomright', // Choose one: 'inline', 'bottomright', 'bottomleft'
      //     theme: 'dark' // Optional
      //   }
      // }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);