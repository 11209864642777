import styled from "styled-components";

export const Img = styled.img` 
position:fixed;
height: 80px;
width: auto;
bottom:20px;
left: 20px;
transition: .3s;
z-index: 999999999999999999999;
&:hover{
transform: scale(1.1);
}
`;