import {SectionClients } from './style.js';
import SimpleSlider from "../Carousel/index.js"

function Clients() {
  return (
   <>
   <SectionClients id='clients'>
    <h2>Clientes</h2>
    <p>Alguns dos nossos clientes que aprovam nossos serviços</p>
    <SimpleSlider></SimpleSlider>


   </SectionClients>
    
   </>
  );
}

export default Clients;
