import {Container, Box} from './style.js';
import Logotipo from '../../assets/logo.png';

function Footer() {
  return (
    <Container>
        <div className='content'>
            <Box>
                <img src={Logotipo} />
            </Box>
            <Box>
                <h2>Contatos</h2>
                <h4>Suporte</h4>
                <h4>suporte@contavares.com.br</h4>
                <h4>(85) 0 0000-0000</h4>
            </Box>
        </div>
    </Container>
  );
}
export default Footer;