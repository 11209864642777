import './App.css';
import WhatsappButton from './components/WhatsappButton';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Clients from './components/Clients';
import About from './components/About';
import Services from './components/Services';
import Depoiments from './components/Depoiments';
import Stripe from './components/Stripe';
import FormSection from './components/Form';
import Footer from './components/Footer';
import Cnpj from './components/Cnpj';

function App() {
  return (
   <>
    <WhatsappButton />
    <Navbar />
    <HeroSection />
    <Clients />
    <About />
    <Services />
    <Depoiments />
    <Stripe />
    <FormSection />
    <Footer />
    <Cnpj />
   </>
  );
}

export default App;
