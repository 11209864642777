import styled from "styled-components";


export const Container = styled.section` 
height: max-content;
width: 100%;
display: flex;
flex-direction: row;
padding: 50px 0px;


@media (max-width: 768px){
height: max-content;
flex-direction: column;
padding: 20px 0px;
background: transparent;
}
`;

export const Box1 = styled.div` 
width:100%;
height: 90vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: start;
text-align: left;
padding-left: 100px;
gap: 20px;

@media (max-width: 768px){
padding-left: 20px;
height: max-content;

}

h1{
font-size: 3em;
color: #101D95;
text-align: left;
}

p{
width: 85%;
color: #000;
font-size: 1.1em;
text-align: justify;
}

a{
background-color: #101D95;
border-radius: 40px;
padding: 16px 50px;
color: #fff;
text-decoration: none;
transition: .3s;
}

a:hover{
transform: scale(1.1);
background-color: #000;
}

@media (max-width: 768px){

h1{
font-size: 2em;
}

}
`;

export const Box = styled.div` 
width:100%;
height: 100vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: left;
@media (max-width: 768px){
height: 90vh;
}



img{
height: 650px;
width: auto;
transition: .3s;
}

img:hover{
transform: scale(1.1);
}

@media (max-width: 768px){

img{
height: 60%;
}
}
`;