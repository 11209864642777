import styled from "styled-components";

export const SectionClients = styled.section` 
height: max-content;
width: 100%;
display: flex;
flex-direction: column;
padding-left: 100px;
padding-right: 100px;
padding-top: 80px;
padding-bottom: 80px;
@media (max-width: 976px){
padding-left: 20px;
padding-right: 20px;
}

h2{
font-size: 4em;
}

p{
font-weight: 300;
font-size: 1em;
}

@media (max-width: 976px){
h2{
font-size: 2em;
}
}
`;