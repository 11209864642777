import styled from "styled-components";


export const CarouselContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 100px;
  padding-left: 100px;
  gap: 30px;

  .box {
    background-color: #fff;
    padding: 40px;
  }

  .content {
    background-color: #fff;
    padding: 40px 50px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1), 0px 9px 51px rgba(0, 0, 0, 0.06);
  }

  h2 {
    margin-top: 20px;
    font-size: 25px;
    color: #101D95;
    text-align: start;
  }

  @media (max-width: 1024px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 480px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const Slider = styled.div`
  .slick-slide {
    width: 100%;
    padding: 20px;

    img {
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .slick-prev,
  .slick-next {
    font-size: 24px;
    z-index: 1;
    background: hsla(242, 88.4%, 45.3%, 1); /* Cor de fundo padrão */
    border-radius: 50%; /* Botões arredondados */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      color: white; /* Cor do ícone */
    }
  }

  /* Personalização dos botões de navegação */
  .slick-next {
    right: 30px !important;
    background-color: #26D07C;  /* Cor de fundo personalizada */
    &:before {
      content: '→';  /* Ícone de avançar */
      color: white;  /* Cor do ícone */
    }
  }

  .slick-prev {
    left: 30px !important;
    background-color: #101D95;  /* Cor de fundo personalizada */
    &:before {
      content: '←';  /* Ícone de voltar */
      color: white;  /* Cor do ícone */
    }
  }

  .slick-dots li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .slick-active {
    color: #000;
    background-color: #101D95;
  }

  /* Responsividade */
  @media (max-width: 1024px) {
    .slick-prev, .slick-next {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    .slick-prev, .slick-next {
      font-size: 18px;
    }

    .slick-dots li {
      width: 15px;
      height: 15px;
    }
  }

  @media (max-width: 480px) {
    .slick-prev, .slick-next {
      font-size: 16px;
    }

    .slick-dots li {
      width: 12px;
      height: 12px;
    }
  }
`;