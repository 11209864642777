import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Box, Form, Button } from './style.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FormSection() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const notify = () => toast("Email enviado com sucesso!");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_q91uvzo', 
      'template_6qrlek3', 
      formData,
      'w1QBuIMhngdm1EymH' 
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      notify();
    }, (error) => {
      console.error('FAILED...', error);
      alert('Ocorreu um erro ao enviar a mensagem.');
    });
  };

  return (
    <Container id='contato'>
      <Box>
        <h2>Agende uma Reunião</h2>
        <h3>Vamos conversar sobre suas necessidades específicas</h3>
      </Box>
      <Box>
        <Form onSubmit={handleSubmit}>
          <div className="form__group field">
            <input
              type="text"
              className="form__field"
              placeholder="Nome"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="name" className="form__label">Nome</label>
          </div>
          <div className="form__group field">
            <input
              type="email"
              className="form__field"
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="email" className="form__label">E-mail</label>
          </div>
          <div className="form__group field">
            <textarea
              className="form__field"
              placeholder="Mensagem"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <label htmlFor="message" className="form__label">Mensagem</label>
          </div>
          <Button type="submit">Enviar</Button>
        </Form>
      </Box>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
}

export default FormSection;