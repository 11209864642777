import styled from "styled-components";
import Background from "../../assets/background.jpg";

export const Container = styled.section` 
height: 80vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 30px;
padding-right: 30px;
align-items: center;
background:  linear-gradient(to bottom, rgb(27 28 28 / 20%), rgba(0, 0, 255, 0.7)), url(${Background});
background-position: center;
background-size: cover;
gap: 10px;

h1{
font-size: 3em;
color: #fff;
text-align: center;
margin: 0;
}

p{
font-size: 1.2em;
color: #fff;
text-align: center;
font-weigth: 400;
}

@media (max-width: 768px){
height: 80vh;
padding: 60px 30px;
gap: 10px;

h1{
font-size: 1.5em;
}

p{
font-size: 1em;
color: #fff;
text-align: center;
font-weigth: 400;
}
}
`;