import {Img} from './style.js';
import WhatsApp from '../../assets/whatsapp.png';
function WhatsappButton() {
  return (
   <>
   <a href='#' target='_blank'>
    <Img src={WhatsApp}/>
   </a>
   </>
  );
}
export default WhatsappButton;