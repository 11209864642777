import styled from "styled-components";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
background: #fff;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
padding: 60px 0px;
gap: 20px;
}
`;

export const Box = styled.div` 
width:100%;
height: 100vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;

h2{
font-size: 3em;
}

h3{
font-size: 1em;
font-weight: 400;
}

@media (max-width: 768px){
height: max-content;
flex-direction: column;
text-align: center;

h2{
font-size: 2em;
}
}

`;

export const Form = styled.form` 
height: auto;
width: 100%;
display: flex;
flex-direction: column;
gap: 30px;



.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 90%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #000;
  outline: 0;
  font-size: 20px;
  color: #000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #000;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 400;
  border-width: 3px;
  border-image: linear-gradient(to right, #101D95, #101D95);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #101D95;
  font-weight: 700;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}

@media (max-width: 768px){
justify-content: center;
align-items: center;

h2{
font-size: 2em;
}
}

`;

export const Button = styled.button` 
height: 40px;
width: 160px;
background: #000;
border-radius: 50px;
border: none;
color: #fff;
transition: .3s;
cursor: pointer;
&:hover{
background: #101D95;
transform: scale(1.1);
color:#fff;
}
`;