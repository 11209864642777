import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 50px;

  h4{
  color: #000;
  font-weight: 500;
  }

  @media (max-width: 768px){
    padding: 0px;
    text-align: center;

    h4{
    font-size: .5em;
    }

`;