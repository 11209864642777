import React from "react";
import { CarouselContainer, Slider as StyledSlider } from './style.js'; 
import ReactSlick from "react-slick"; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,  
    centerMode: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,
          centerPadding: "15px", 
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1,
          centerPadding: "10px", 
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,  
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      <ReactSlick {...settings} style={{gap: "40px"}}>
        <div className="box">
          <div className="content">
            <h2>Thiago</h2>
            <p>There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected humour,
            or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Thiago</h2>
            <p>There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected humour,
            or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Thiago</h2>
            <p>There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected humour,
            or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Thiago</h2>
            <p>There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form, by injected humour,
            or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
          </div>
        </div>
      </ReactSlick>
    </CarouselContainer>
  );
}
