import {Container,Box, Box1 } from './style.js';
import Img from "../../assets/about.png";
import SimpleSlider from '../CarouselDepoiments/index.js';

function Depoiments() {
  return (
   <>
    <Container id='depoiments'>
        <Box1>
            <p>O que dizem nossos clientes?</p>
            <h1>Depoimentos</h1>
        </Box1>
        <SimpleSlider />

    </Container>
   </>
  );
}
export default Depoiments;
