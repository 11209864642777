import styled from "styled-components";

export const Header = styled.header`
height:100px;
width: 100%;
background: transparent;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
position: absolute;
top: 0;
left: 0;

@media (min-width: 768px){
svg{
display:none;
}
}
`;

export const Img = styled.img`
height: 50px;
width: auto;
`;

export const BoxItens = styled.ul`
display: flex;
flex-direction: row;
gap: 20px;

a{
text-decoration: none;
}

li{
list-style: none;
color: #fff;
transition: .3s;
}

li:hover{
color: #101D95;
}

@media (max-width: 768px){
display: none;

li{
color: #000;
}
}

`;


export const BoxItensResponsive = styled.ul`
display: flex;
flex-direction: row;
gap: 20px;

a{
text-decoration: none;
}

li{
list-style: none;
}

position: absolute;
top: 80px;
left: 0;
background: #fff;
flex-direction: column;
width: 100%;
z-index: 99999999;
padding: 20px 20px 20px 20px;


li{
color: #000;
}
}

`;