import styled from "styled-components";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
background-color: #E3DFDC;
padding-left: 100px;
padding-right: 100px;
justify-content: center;
gap: 30px;
@media (max-width: 768px){
height: max-content;
padding-left: 20px;
padding-right: 20px;
padding-top: 50px;
padding-bottom: 50px;
}

h2{
font-size: 2.5em;
}

p{
font-weight: 500;
font-size: 1em;
width: 50%;
color: #818181;
}

@media (max-width: 768px){
p{
font-weight: 500;
font-size: 1em;
width: 100%;
color: #818181;
}
}
`;

export const Content = styled.div`
display: flex;
flex-direction: row;
height: max-content;
width: 100%;
gap: 30px;
background-color: transparent;
@media (max-width: 768px){
flex-direction: column;

}
`;

export const Box = styled.div`
display: flex;
flex-direction: column;
height: max-content;
width: 100%;
gap: 15px;
background-color: #fff;
padding: 40px;
border-radius: 20px;

h3{
font-size: 30px;
}

.boxer{
display: flex;
flex-direction: row;
align-items: center;
gap: 20px;
}

.blue{
background-color: #101D95;
border-radius: 100%;
height: 45px;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
}

hr{
width: 100%;
background-color: #D0D0D0;
height: 1px;
}

@media (max-width: 768px){
.blue{
height: 40px;

}

}

`;