import React from 'react';
import { CarouselWrapper, CarouselContent, CarouselItem } from './style.js';
import Correct from "../../assets/correct.png";

function Stripe() {
  const items = [
    { id: 1, text: 'Contabilidade' },
    { id: 2, text: 'Imposto de renda' },
    { id: 3, text: 'Planejamento tributário' },
    { id: 4, text: 'Legalização de empresas' },
  ];

  return (
    <CarouselWrapper>
      <CarouselContent>
        {/* Renderiza os itens duas vezes para criar continuidade */}
        {items.concat(items).map((item, index) => (
          <CarouselItem key={index}>
            <img src={Correct} alt="Ícone" />
            <span>{item.text}</span>
          </CarouselItem>
        ))}
      </CarouselContent>
    </CarouselWrapper>
  );
}

export default Stripe;
