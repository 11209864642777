import {Container, Box} from './style.js';

function HeroSection() {

  return (
    <Container id='hero'>
            <h1>
                Gestão Contábil e Fiscal de Confiança para sua Empresa
            </h1>
            <p>Transforme sua Gestão com Nossos Serviços Contábeis Especializados</p>
    </Container>
  );
}
export default HeroSection;